import { CampaignInfo } from '@lib/services/contentful/getCampaigns';

export const getActiveCampaigns = (campaigns: CampaignInfo['campaigns']) => {
  const activeCampaigns =
    campaigns?.filter((campaign) => {
      const today = new Date();
      const start = new Date(campaign?.campaignStart);
      const end = new Date(campaign?.campaignEnd);
      return (
        start.getTime() < today.getTime() && end.getTime() > today.getTime()
      );
    }) ?? [];

  return activeCampaigns;
};
