import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/blocks/RichTextAccordion.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/blocks/Store.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Carousel/Carousel.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Frame/Frame.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Heading/Heading.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/icon/icon.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/Link/Link.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/ProductList/ProductListCarouselItem.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/components/RichText/LazyRichText.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/popups/forms/BookMeetingForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/popups/forms/ClaimsForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/popups/forms/ContactForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/popups/forms/MatchPriceForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/popups/forms/NewCustomerDiscountForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/ui/popups/forms/ReturnsForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lanna/src/utils/imageLoader.ts");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.26.0_@opentelemetry+api@1.6.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js")