import type { Maybe } from '@business/gql/graphql';
import { getActiveCampaigns } from './getActiveCampaigns';
import getSortedCampaigns from './getSortedCampaigns';
import { CampaignInfo } from '@lib/services/contentful/getCampaigns';

export const getMatchingActiveCampaignsForProduct = ({
  order,
  campaigns,
  productFlags,
}: CampaignInfo & {
  productFlags: Maybe<string>[];
}) => {
  if (!campaigns?.length) return [];
  if (productFlags.length === 0) return [];

  const sortedCampaigns = getSortedCampaigns({
    campaigns,
    order,
  });

  const matchingCampaigns = sortedCampaigns?.filter((campaign) =>
    productFlags.includes(campaign?.commerceId ?? ''),
  );

  if (!matchingCampaigns) return [];

  const activeCampaigns = getActiveCampaigns(matchingCampaigns);

  return activeCampaigns;
};
