'use client';

import type {
  CampaignFragment,
  CategoryProductFragment,
  ProductFragment,
  ProductServiceProduct,
} from '@business/gql/graphql';
import type { Dictionary } from '@ts/dictionary';
import type { Product } from '@ts/product';
import { getMatchingActiveCampaignsForProduct } from '@utils/campaign/getMatchingActiveCampaignsForProduct';
import Badge from '../Badge/Badge';
import { useMemo } from 'react';
import { CampaignInfo } from '@lib/services/contentful/getCampaigns';

type ProductType =
  | ProductServiceProduct
  | ProductFragment
  | CategoryProductFragment
  | Product;

function getSortedCampaigns({ campaigns, order }: CampaignInfo) {
  if (!campaigns?.length) {
    return undefined;
  }
  if (!order) {
    return campaigns;
  }

  const priorityMap = new Map(order.map((id, index) => [id, index]));

  const sortedCampaigns = [...campaigns].sort(
    (a, b) =>
      (priorityMap.get(a?.sys.id) ?? Infinity) -
      (priorityMap.get(b?.sys.id) ?? Infinity),
  );

  return sortedCampaigns;
}

const ProductCardBadges = ({
  product,
  dictionary,
  campaignInfo,
}: {
  product: ProductType;
  dictionary: Dictionary;
  campaignInfo?: CampaignInfo;
}) => {
  const data = useMemo(() => {
    if (!campaignInfo) return;
    const { campaigns: items, order } = campaignInfo;
    const campaigns = getMatchingActiveCampaignsForProduct({
      campaigns: items,
      productFlags: product.flags ?? [],
    });
    const sortedCampaigns = getSortedCampaigns({ campaigns, order });
    const campaign = sortedCampaigns?.at(0);
    const isCampaignPrice = product.priceType === 'campaign';
    const shouldShowCampaignBadge =
      campaign?.campaignBadge &&
      (isCampaignPrice || campaign.showBadgeForAllProductsInCampaign);
    const shouldShowSaleBadge = !shouldShowCampaignBadge && isCampaignPrice;
    return {
      shouldShowCampaignBadge,
      shouldShowSaleBadge,
      activeCampaign: campaign,
      isNew: product?.flags?.includes('news'),
    };
  }, [product, campaignInfo]);

  return (
    <div className="absolute left-2 top-2 flex flex-col items-start gap-1">
      {data !== undefined && (
        <>
          {data.shouldShowCampaignBadge &&
            data.activeCampaign?.campaignBadge && (
              <Badge label={data.activeCampaign.campaignBadge} />
            )}
          {data.shouldShowSaleBadge && <Badge label={dictionary?.sale} />}
          {data.isNew && (
            <Badge
              className="bg-green text-white/90"
              label={dictionary?.newsBadge}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProductCardBadges;
