import React, { ReactNode } from 'react';
import { cn } from '@ngg/storefront-utils';
import { Dictionary } from '@ts/dictionary';
import Price, { PriceDetails } from '@ui/components/Price/Price';
import ProductCardRoot from './ProductCardRoot';
import Paragraph from '../Paragraph/Paragraph';
import Stock from '../Stock/Stock';
import { ProductFragment } from '@business/gql/graphql';

type Props = {
  Badges?: ReactNode;
  limitedEdition?: string;
  brand?: string;
  title: string;
  PreviewImage?: ReactNode;
  children?: ReactNode;
  secondaryImage?: string;
  TopComponent?: ReactNode;
  dense?: boolean;
  moreProductVariants?: string;
  stockStatus: {
    stockStatus: string;
    nextDeliveryDate?: string | null;
  };
  stockStatusDictionary?: Dictionary;
  classNames?: {
    [key in 'price' | 'title']?: string;
  };
  priceDetails: PriceDetails;
  priceDictionary: Dictionary;
  productId: string;
  productType?: 'Product' | 'Variant';
};

export default function ProductCard(props: Props) {
  const {
    Badges,
    limitedEdition,
    secondaryImage,
    children,
    TopComponent,
    dense,
    PreviewImage,
    title,
    moreProductVariants,
    stockStatus,
    brand,
    stockStatusDictionary,
    classNames,
    priceDetails,
    priceDictionary,
    productId,
    productType,
  } = props;

  return (
    <ProductCardRoot productId={productId}>
      <div
        className={cn(
          'group relative flex aspect-portrait overflow-hidden bg-beige',
        )}>
        {TopComponent || null}
        {Badges || null}
        {stockStatus?.stockStatus && stockStatusDictionary && (
          <div
            className={cn(
              'absolute bottom-2 left-2 z-10 italic',
              secondaryImage && 'transition-opacity lg:group-hover:opacity-0',
            )}>
            <Stock
              item={stockStatus as ProductFragment}
              type="plp"
              dictionary={stockStatusDictionary}
            />
          </div>
        )}

        {moreProductVariants && (
          <Paragraph
            size="xxsmall"
            className={cn(
              'absolute bottom-2 right-2 z-10 italic leading-none',
              secondaryImage &&
                'transition-oParagraphacity lg:group-hover:opacity-0',
            )}>
            {`+ ${moreProductVariants}`}
          </Paragraph>
        )}

        {PreviewImage && PreviewImage}
        {children && children}
        {limitedEdition && (
          <Paragraph
            size="xxsmall"
            className="absolute inset-x-0 bottom-0 bg-beige text-center uppercase text-black">
            {limitedEdition}
          </Paragraph>
        )}
      </div>

      <div className={cn(dense ? 'pb-3.5 pt-2' : 'flex flex-col pt-3')}>
        {brand && (
          <Paragraph
            size="xsmall"
            className={cn(
              'truncate pb-1 font-sans font-bold uppercase leading-none',
            )}>
            {brand}
          </Paragraph>
        )}

        <Paragraph
          size="small"
          className={cn('mb-2 truncate', dense && 'text-xs', classNames?.title)}
          data-testid="ProductCard-title">
          {title}
        </Paragraph>

        <Price
          priceDetails={priceDetails}
          dictionary={priceDictionary}
          className={classNames?.price}
          productType={productType}
        />
      </div>
    </ProductCardRoot>
  );
}
