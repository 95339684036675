'use client';

import type { ProductServiceProduct } from '@business/gql/graphql';
import { Dictionary } from '@ts/dictionary';
import { CarouselItem } from '@ui/components/Carousel/Carousel';
import PrefetchLink from '@ui/components/Link/PrefetchLink';
import ProductCard from '@ui/components/ProductCard/ProductCard';
import Thumbnail from '@ui/components/Thumbnail/Thumbnail';
import { market } from '@utils/config';
import currencies from '@utils/currency/currencies';
import { gTagSelectListItem } from '@utils/googleTags';
import { Suspense } from 'react';
import ProductCardBadges from '../ProductCard/ProductCardBadges';
import { CampaignInfo } from '@lib/services/contentful/getCampaigns';

type Props = {
  product: ProductServiceProduct;
  pdpDictionary: Dictionary;
  campaignInfo?: CampaignInfo;
};

const ProductListCarouselItem = (props: Props) => {
  const { product, pdpDictionary, campaignInfo } = props;

  return (
    <CarouselItem className="basis-[75%] md:basis-[40%] lg:basis-1/6 xl:basis-1/6">
      <PrefetchLink
        href={product.slug}
        onClick={() => gTagSelectListItem(product, currencies[market])}>
        <ProductCard
          productId={product.id}
          secondaryImage={product.media[1]?.src}
          Badges={
            <ProductCardBadges
              product={product}
              dictionary={pdpDictionary}
              campaignInfo={campaignInfo}
            />
          }
          title={product.title ?? ''}
          brand={product.brand}
          priceDictionary={{
            from: pdpDictionary?.from,
            recommendedPrice: pdpDictionary?.recommendedPrice,
          }}
          priceDetails={{
            priceType: product?.priceType,
            fromPrice: product?.fromPrice ?? undefined,
            price: product?.price,
            presentationPrice: product?.presentationPrice,
            presentationOriginal: product?.presentationOriginal ?? undefined,
            presentationRrpWhenCampaign:
              product?.presentationRrpWhenCampaign ?? undefined,
            rrpWhenCampaign: product?.rrpWhenCampaign ?? undefined,
            originalPrice: product?.originalPrice ?? undefined,
          }}
          moreProductVariants={
            product?.variantCount > 1 ? pdpDictionary?.moreOptions : undefined
          }
          stockStatus={{
            stockStatus: product.stockStatus,
            nextDeliveryDate: product.nextDeliveryDate,
          }}
          stockStatusDictionary={pdpDictionary}>
          <Suspense>
            <Thumbnail
              title={product.title ?? ''}
              src={product.media[0]?.src}
              alternateSrc={product.media[1]?.src}
            />
          </Suspense>
        </ProductCard>
      </PrefetchLink>
    </CarouselItem>
  );
};

ProductListCarouselItem.displayName = 'ProductListCarouselItem';

export default ProductListCarouselItem;
