import { CampaignInfo } from '@lib/services/contentful/getCampaigns';

export default function getSortedCampaigns({ campaigns, order }: CampaignInfo) {
  if (!campaigns?.length) {
    return undefined;
  }
  if (!order) {
    return campaigns;
  }

  const priorityMap = new Map(order.map((id, index) => [id, index]));

  const sortedCampaigns = [...campaigns].sort(
    (a, b) =>
      (priorityMap.get(a?.sys.id) ?? Infinity) -
      (priorityMap.get(b?.sys.id) ?? Infinity),
  );

  return sortedCampaigns;
}
