import { cn } from '@ngg/storefront-utils';
import { formatPrice } from '@utils/currency/formatPrice';
import * as styles from './Price.styles';
import type { Dictionary } from '@ts/dictionary';
import Paragraph from '../Paragraph/Paragraph';

export type PriceDetails = {
  priceType?: string;
  fromPrice?: boolean;
  price?: number;
  presentationPrice?: number;
  presentationOriginal?: number;
  presentationRrpWhenCampaign?: number;
  rrpWhenCampaign?: number;
  originalPrice?: number;
};

export default function Price({
  className,
  productType = 'Product',
  priceDetails,
  dictionary,
}: {
  className?: string | null;
  productType?: string;
  dictionary?: Dictionary;
  priceDetails: PriceDetails;
}) {
  const isVariant = productType == 'Variant';
  return (
    <div className={cn(styles.container(), className)}>
      {priceDetails?.priceType === 'priceMatch' && (
        <div className={styles.comparisonPriceContainer()}>
          <Paragraph className={styles.mainPriceParagraph()}>
            {priceDetails.fromPrice && `${dictionary?.from} `}
            <span className="text-red-600">
              {formatPrice(
                isVariant ? priceDetails.price : priceDetails.presentationPrice,
              )}
            </span>
          </Paragraph>
          <Paragraph className={styles.comparisionPriceParagraph()}>
            ({dictionary?.recommendedPrice}{' '}
            {formatPrice(
              isVariant
                ? priceDetails.rrpWhenCampaign ?? priceDetails.originalPrice
                : priceDetails.presentationRrpWhenCampaign ??
                    priceDetails.presentationOriginal,
            )}
            )
          </Paragraph>
        </div>
      )}
      {priceDetails?.priceType === 'campaign' && (
        <div className={styles.comparisonPriceContainer()}>
          <Paragraph className={styles.mainPriceParagraph()}>
            {priceDetails.fromPrice && `${dictionary?.from} `}
            <span className="text-red-600">
              {formatPrice(
                isVariant ? priceDetails.price : priceDetails.presentationPrice,
              )}
            </span>
          </Paragraph>
          <Paragraph
            className={cn([
              styles.comparisionPriceParagraph(),
              'line-through',
            ])}>
            {formatPrice(
              isVariant
                ? priceDetails.rrpWhenCampaign ?? priceDetails.originalPrice
                : priceDetails.presentationRrpWhenCampaign ??
                    priceDetails.presentationOriginal,
            )}
          </Paragraph>
        </div>
      )}
      {priceDetails?.priceType === 'standard' && (
        <Paragraph className={styles.mainPriceParagraph()}>
          {priceDetails.fromPrice && `${dictionary?.from} `}
          {formatPrice(
            isVariant ? priceDetails.price : priceDetails.presentationPrice,
          )}
        </Paragraph>
      )}
    </div>
  );
}
